import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../services/form.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MailService } from '../services/mail.service';

@Component({
	selector: 'app-finish',
	templateUrl: './finish.component.html',
	styleUrls: ['./finish.component.scss'],
})
export class FinishComponent {
	subtitle: string = `En los próximos días te enviaremos el contrato al email que nos has indicado para que lo revises y lo firmes. <br><br>
	A continuación, necesitamos que selecciones el método de firma del contrato que desearás utilizar cuando te lo enviemos.`;
	step: number = 1;
	buttonLabel: string = 'Volver';
	switchAutorizacion: boolean = false;
	switchTratamientoDatos: boolean = false;

	telefono: FormControl = new FormControl('', [
		Validators.required,
		Validators.pattern(/^\+?[0-9]{1,3}?[0-9]{7,15}$/),
	]);

	form = new FormGroup({
		telefono: this.telefono,
	});

	showModal: boolean = false;
	phoneValid: boolean = false;
	enableSendInformation: boolean = true;

	constructor(private router: Router, public formService: FormService, private readonly mail: MailService) {}

	ngOnInit(): void {}

	buttonAction(option: number) {
		if (this.step === 1) this.router.navigate(['/resume']);
	}

	valueChange(event: any, value: string) {
		const valueSelected = event.find((val: any) => {
			return !!val.value;
		});

		if (value === 'switchAutorizacion' && !!valueSelected)
			(this.switchAutorizacion = valueSelected.value && (valueSelected.id === 'yes' ? true : false)),
				(this.enableSendInformation = valueSelected.id === 'no' ? true : false);
	}

	clickEvent(event: any) {
		if (this.form.valid) (this.phoneValid = true), (this.enableSendInformation = false);
	}

	sendInformationEmail() {
		this.enableSendInformation = true;

		//recopilar la información de todo el formulario y mandarla al correo formateada
		this.mail.sendMailFormatted(this.formService.getForm()).subscribe({
			next: (data) => {
				this.showModal = true;
			},
			error: () => {
				this.enableSendInformation = false;
			},
		});
	}

	closeModal(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				this.showModal = false;
				this.enableSendInformation = true;
				this.formService.resetForm();
				this.router.navigate(['/']);
				break;
			//Cancelar
			case 'buttonSecondary':
				this.showModal = false;
				this.enableSendInformation = true;
				this.formService.resetForm();
				this.router.navigate(['/']);
				break;
			default:
				this.showModal = false;
				this.enableSendInformation = true;
				this.formService.resetForm();
				this.router.navigate(['/']);
				break;
		}
	}
}

<tec-create-layout>
	<tec-item title="Solicitud de alta" [highlight]="true">
		<tec-tag [status]="'success'">{{
			formService.getForm().channelBoth === 'ONLINE' ? 'CANAL ONLINE' : registratonType
		}}</tec-tag>
	</tec-item>

	<tec-stepper-progress [steps]="steps" (clickEvent)="clickEvent($event)"></tec-stepper-progress>
	<ng-container [ngSwitch]="step">
		<ng-container *ngSwitchCase="1">
			<tec-tile type="default" styling="default" [title]="'Tipo de negocio'">
				<tec-toggle
					[options]="[
						{ id: 'sociedad', label: 'Sociedad' },
						{ id: 'autonomo', label: 'Autónomo' }
					]"
					[multiple]="false"
					(valueChange)="changeClass($event)"
				></tec-toggle>
			</tec-tile>
			<form [formGroup]="datosForm" class="gap-32">
				<ng-container *ngIf="isClickedSoc || isClickedAut">
					<tec-tile type="default" styling="default" [title]="'General'">
						<div class="grid grid-i3">
							<tec-form-group label="País">
								<tec-select
									[placeholder]="'Selecciona el país'"
									[options]="paises"
									[search]="true"
									(valueChange)="valueChange($event)"
									formControlName="pais"
									[ngClass]="{
										'input--error': datosForm.get('pais')?.invalid && datosForm.get('pais')?.touched
									}"
									[hasError]="datosForm.get('pais')?.invalid && datosForm.get('pais').touched"
								></tec-select>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('pais')?.invalid && datosForm.get('pais')?.touched"
								>
									El país es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Nombre del comercio">
								<tec-input
									formControlName="nombreComercio"
									type="text"
									[ngClass]="{
										'input--error':
											datosForm.get('nombreComercio')?.invalid &&
											datosForm.get('nombreComercio')?.touched
									}"
									[hasError]="
										datosForm.get('nombreComercio')?.invalid &&
										datosForm.get('nombreComercio').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('nombreComercio')?.invalid &&
										datosForm.get('nombreComercio')?.touched
									"
								>
									El nombre es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Página web">
								<tec-input
									formControlName="web"
									type="text"
									[ngClass]="{
										'input--error': datosForm.get('web')?.invalid && datosForm.get('web')?.touched
									}"
									[hasError]="datosForm.get('web')?.invalid && datosForm.get('web').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('web')?.invalid && datosForm.get('web')?.touched"
								>
									La página web es requerida.
								</div>
							</tec-form-group>
						</div>
						<div class="grid grid-i2">
							<tec-form-group label="Sector al que pertenece">
								<tec-select
									[placeholder]="'Selecciona el sector'"
									[options]="sectores"
									[search]="true"
									(valueChange)="valueChange($event)"
									formControlName="sector"
									[ngClass]="{
										'input--error':
											datosForm.get('sector')?.invalid && datosForm.get('sector')?.touched
									}"
									[hasError]="datosForm.get('sector')?.invalid && datosForm.get('sector').touched"
								></tec-select>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('sector')?.invalid && datosForm.get('sector')?.touched"
								>
									El sector es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Descripción de la actividad">
								<tec-textarea
									formControlName="actividad"
									cols="15"
									rows="15"
									[placeholder]="
										'Máximo 400 caracteres. Recuerda que la parte visible serán 210 caracteres, intenta concentrar la información necesaria en esos primeros 210'
									"
									[ngClass]="{
										'input--error':
											datosForm.get('actividad')?.invalid && datosForm.get('actividad')?.touched
									}"
									[hasError]="
										datosForm.get('actividad')?.invalid && datosForm.get('actividad').touched
									"
								></tec-textarea>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('actividad')?.invalid && datosForm.get('actividad')?.touched"
								>
									La actividad es requerida.
								</div>
							</tec-form-group>
						</div>
					</tec-tile>
					<tec-tile
						type="default"
						styling="default"
						[title]="'¿Está tu comercio adherido a alguno de los siguientes programas?'"
					>
						<div class="grid grid-i2">
							<div class="form-group form-group--image">
								<img [src]="'assets/images/hosteleria-espana.svg'" alt="" class="form-group__image" />
								<tec-checkbox
									formControlName="hosteleriaAdherido"
									[label]="'Hostelería de España'"
									(valueChange)="valueChangeCheck($event)"
								></tec-checkbox>
							</div>
							<div class="form-group form-group--image">
								<img [src]="'assets/images/volveremos.svg'" alt="" class="form-group__image" />
								<tec-checkbox
									formControlName="volvemosAdherido"
									[label]="'VOLVEREMOS'"
									(valueChange)="valueChangeCheck($event)"
								></tec-checkbox>
							</div>
						</div>
					</tec-tile>
				</ng-container>

				<!-- SOCIEDAD -->
				<ng-container *ngIf="isClickedSoc">
					<tec-tile type="default" styling="default" [title]="'Detalles de la sociedad'">
						<div class="form-section">
							<div class="grid grid-i3">
								<tec-form-group label="NIF">
									<tec-input
										formControlName="nifSociedad"
										[ngClass]="{
											'input--error':
												datosForm.get('nifSociedad')?.invalid &&
												datosForm.get('nifSociedad')?.touched
										}"
										[hasError]="
											datosForm.get('nifSociedad')?.invalid &&
											datosForm.get('nifSociedad').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('nifSociedad')?.invalid &&
											datosForm.get('nifSociedad')?.touched
										"
									>
										El NIF de la sociedad es requerido.
									</div>
								</tec-form-group>
								<tec-form-group label="Nombre comercial">
									<tec-input
										formControlName="razonSocSociedad"
										[ngClass]="{
											'input--error':
												datosForm.get('razonSocSociedad')?.invalid &&
												datosForm.get('razonSocSociedad')?.touched
										}"
										[hasError]="
											datosForm.get('razonSocSociedad')?.invalid &&
											datosForm.get('razonSocSociedad').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('razonSocSociedad')?.invalid &&
											datosForm.get('razonSocSociedad')?.touched
										"
									>
										El nombre comercial es requerido.
									</div>
								</tec-form-group>
								<tec-form-group label="Email de notificaciones">
									<tec-input
										formControlName="emailSociedad"
										[type]="'email'"
										[ngClass]="{
											'input--error':
												datosForm.get('emailSociedad')?.invalid &&
												datosForm.get('emailSociedad')?.touched
										}"
										[hasError]="
											datosForm.get('emailSociedad')?.invalid &&
											datosForm.get('emailSociedad').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('emailSociedad')?.invalid &&
											datosForm.get('emailSociedad')?.touched
										"
									>
										El email de la sociedad es requerido.
									</div>
								</tec-form-group>
							</div>
							<div class="grid grid-i3">
								<tec-form-group label="Forma de representación de la sociedad">
									<tec-select
										formControlName="representacionSociedad"
										[placeholder]="'Selecciona la forma de representación'"
										[options]="representations"
										[search]="true"
										(valueChange)="valueChange($event)"
										[ngClass]="{
											'input--error':
												datosForm.get('representacionSociedad')?.invalid &&
												datosForm.get('representacionSociedad')?.touched
										}"
										[hasError]="
											datosForm.get('representacionSociedad')?.invalid &&
											datosForm.get('representacionSociedad').touched
										"
									></tec-select>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('representacionSociedad')?.invalid &&
											datosForm.get('representacionSociedad')?.touched
										"
									>
										La forma de representación es requerida.
									</div>
								</tec-form-group>
								<tec-form-group label="Inscripción de la Sociedad en el Registro Mercantil">
									<tec-input
										formControlName="inscripcionSociedad"
										[placeholder]="'Ciudad, tomo, folio y hoja'"
										[ngClass]="{
											'input--error':
												datosForm.get('inscripcionSociedad')?.invalid &&
												datosForm.get('inscripcionSociedad')?.touched
										}"
										[hasError]="
											datosForm.get('inscripcionSociedad')?.invalid &&
											datosForm.get('inscripcionSociedad').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('inscripcionSociedad')?.invalid &&
											datosForm.get('inscripcionSociedad')?.touched
										"
									>
										La inscripción de la sociedad es requerida.
									</div>
								</tec-form-group>
							</div>
						</div>
						<div class="form-section">
							<span class="form-section__title">REPRESENTANTE</span>
							<div class="grid grid-i3">
								<tec-form-group label="Nombre y apellidos">
									<tec-input
										formControlName="nombreRepresentante"
										[placeholder]="'Nombre y apellidos'"
										[ngClass]="{
											'input--error':
												datosForm.get('nombreRepresentante')?.invalid &&
												datosForm.get('nombreRepresentante')?.touched
										}"
										[hasError]="
											datosForm.get('nombreRepresentante')?.invalid &&
											datosForm.get('nombreRepresentante').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('nombreRepresentante')?.invalid &&
											datosForm.get('nombreRepresentante')?.touched
										"
									>
										El nombre del representate es requerido.
									</div>
								</tec-form-group>
								<tec-form-group label="DNI">
									<tec-input
										formControlName="dniRepresentante"
										[placeholder]="'DNI'"
										[ngClass]="{
											'input--error':
												datosForm.get('dniRepresentante')?.invalid &&
												datosForm.get('dniRepresentante')?.touched
										}"
										[hasError]="
											datosForm.get('dniRepresentante')?.invalid &&
											datosForm.get('dniRepresentante').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('dniRepresentante')?.invalid &&
											datosForm.get('dniRepresentante')?.touched
										"
									>
										El DNI del representate es requerido o no válido.
									</div>
								</tec-form-group>
								<tec-form-group label="Email profesional de contacto">
									<tec-input
										formControlName="emailRepresentante"
										[type]="'email'"
										[ngClass]="{
											'input--error':
												datosForm.get('emailRepresentante')?.invalid &&
												datosForm.get('emailRepresentante')?.touched
										}"
										[hasError]="
											datosForm.get('emailRepresentante')?.invalid &&
											datosForm.get('emailRepresentante').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('emailRepresentante')?.invalid &&
											datosForm.get('emailRepresentante')?.touched
										"
									>
										El email del representate es requerido.
									</div>
								</tec-form-group>
							</div>
							<div class="grid grid-i3">
								<tec-form-group label="Teléfono profesional de contacto">
									<tec-input
										formControlName="telefonoProfesionalRepresentante"
										[ngClass]="{
											'input--error':
												datosForm.get('telefonoProfesionalRepresentante')?.invalid &&
												datosForm.get('telefonoProfesionalRepresentante')?.touched
										}"
										[hasError]="
											datosForm.get('telefonoProfesionalRepresentante')?.invalid &&
											datosForm.get('telefonoProfesionalRepresentante').touched
										"
									></tec-input>
									<div
										class="form-group__error-message"
										*ngIf="
											datosForm.get('telefonoProfesionalRepresentante')?.invalid &&
											datosForm.get('telefonoProfesionalRepresentante')?.touched
										"
									>
										El teléfono profesional del representate es requerido.
									</div>
								</tec-form-group>
							</div>
						</div>
					</tec-tile>
					<tec-tile
						type="default"
						tooltip="Ten en cuenta que los poderes de representación se te han podido conferir en cualquier tipo de escritura pública. Por ejemplo, si eres administrador único de tu empresa, es posible que estos se te confiriesen en la escritura de constitución de tu negocio o con ocasión de tu nombramiento."
						[title]="'Escritura'"
						subtitle="Escritura notarial que acredite que el firmante tiene los poderes suficientes para representar a la sociedad y firmar el contrato con Pensumo."
					>
						<tec-form-group>
							<tec-upload
								formControlName="escritura"
								[size]="'sm'"
								[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
								[ngClass]="{
									'input--error':
										datosForm.get('escritura')?.invalid && datosForm.get('escritura')?.touched
								}"
							></tec-upload>
							<div
								class="form-group__error-message"
								*ngIf="datosForm.get('escritura')?.invalid && datosForm.get('escritura')?.touched"
							>
								La escritura del representate es requerida.
							</div>
						</tec-form-group>
					</tec-tile>
					<tec-tile
						*ngIf="showMancomunados"
						[title]="'Administradores mancomunados'"
						subtitle="Indica los datos identificativos de cada mancomunado. Si eres uno de ellos, marca la casilla."
					>
						<tec-table
							[headers]="headers"
							[rows]="rows"
							*ngIf="rows.length > 0"
							[config]="{ clickeable: false, checkbox: false, checkboxAll: false }"
							(cellEvent)="getMenu($event, 'mancomunado')"
						></tec-table>
						<!-- <tec-link [label]="'Añadir'" (click)="addMancomunado()"></tec-link> -->
						<tec-button
							[label]="'Añadir'"
							[type]="'default'"
							[size]="'sm'"
							(clickEvent)="addMancomunado()"
						></tec-button>
					</tec-tile>
				</ng-container>

				<!-- AUTONOMO -->
				<ng-container *ngIf="isClickedAut">
					<tec-tile type="default" styling="default" [title]="'Detalles del autónomo'">
						<div class="grid grid-i3">
							<tec-form-group label="Nombre y apellidos">
								<tec-input
									formControlName="nombreAutonomo"
									[ngClass]="{
										'input--error':
											datosForm.get('nombreAutonomo')?.invalid &&
											datosForm.get('nombreAutonomo')?.touched
									}"
									[hasError]="
										datosForm.get('nombreAutonomo')?.invalid &&
										datosForm.get('nombreAutonomo').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('nombreAutonomo')?.invalid &&
										datosForm.get('nombreAutonomo')?.touched
									"
								>
									El nombre del autónomo es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="DNI">
								<tec-input
									formControlName="dniAutonomo"
									[ngClass]="{
										'input--error':
											datosForm.get('dniAutonomo')?.invalid &&
											datosForm.get('dniAutonomo')?.touched
									}"
									[hasError]="
										datosForm.get('dniAutonomo')?.invalid && datosForm.get('dniAutonomo').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('dniAutonomo')?.invalid && datosForm.get('dniAutonomo')?.touched
									"
								>
									El DNI del autónomo es requerido o no válido.
								</div>
							</tec-form-group>
							<tec-form-group label="Nombre comercial">
								<tec-input
									formControlName="razonSocAutonomo"
									[ngClass]="{
										'input--error':
											datosForm.get('razonSocAutonomo')?.invalid &&
											datosForm.get('razonSocAutonomo')?.touched
									}"
									[hasError]="
										datosForm.get('razonSocAutonomo')?.invalid &&
										datosForm.get('razonSocAutonomo').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('razonSocAutonomo')?.invalid &&
										datosForm.get('razonSocAutonomo')?.touched
									"
								>
									El nombre comercial es requerido.
								</div>
							</tec-form-group>
						</div>
						<div class="grid grid-i3">
							<tec-form-group label="Email profesional de contacto">
								<tec-input
									formControlName="emailProfesional"
									[type]="'email'"
									[ngClass]="{
										'input--error':
											datosForm.get('emailProfesional')?.invalid &&
											datosForm.get('emailProfesional')?.touched
									}"
									[hasError]="
										datosForm.get('emailProfesional')?.invalid &&
										datosForm.get('emailProfesional').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('emailProfesional')?.invalid &&
										datosForm.get('emailProfesional')?.touched
									"
								>
									El email profesional es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Teléfono profesional de contacto">
								<tec-input
									formControlName="telefonoProfesional"
									[ngClass]="{
										'input--error':
											datosForm.get('telefonoProfesional')?.invalid &&
											datosForm.get('telefonoProfesional')?.touched
									}"
									[hasError]="
										datosForm.get('telefonoProfesional')?.invalid &&
										datosForm.get('telefonoProfesional').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('telefonoProfesional')?.invalid &&
										datosForm.get('telefonoProfesional')?.touched
									"
								>
									El teléfono profesional es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Dirección fiscal de facturación">
								<tec-input
									formControlName="direccionFiscal"
									[ngClass]="{
										'input--error':
											datosForm.get('direccionFiscal')?.invalid &&
											datosForm.get('direccionFiscal')?.touched
									}"
									[hasError]="
										datosForm.get('direccionFiscal')?.invalid &&
										datosForm.get('direccionFiscal').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('direccionFiscal')?.invalid &&
										datosForm.get('direccionFiscal')?.touched
									"
								>
									La dirección fiscal de facturación es requerida.
								</div>
							</tec-form-group>
						</div>
					</tec-tile>
				</ng-container>
				<ng-container *ngIf="isClickedSoc || isClickedAut">
					<tec-tile
						type="default"
						styling="default"
						[title]="'Mandato SEPA B2B'"
						subtitle="La forma de cobro de Pensumo será a través de Mandatos SEPA B2B. Se enviarán dos Mandatos SEPA B2B mensuales,
					uno para el pago de las aportaciones que realizas a los planes de pensiones de tus clientes por comprar en tu
					comercio y otro para el pago de la comisión a Pensumo. Por ello, para poder autorizarlos la primera vez que te
					los enviemos, ten activadas las alertas para Mandatos SEPA B2B en tu Banca Digital o Electrónica. De esta manera,
					tu banco te notificará cuando los recibas."
					>
						<div class="grid grid-i3">
							<tec-form-group label="Nombre del deudor">
								<tec-input
									formControlName="nombreDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('nombreDeudor')?.invalid &&
											datosForm.get('nombreDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('nombreDeudor')?.invalid && datosForm.get('nombreDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('nombreDeudor')?.invalid && datosForm.get('nombreDeudor')?.touched
									"
								>
									El nombre es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Dirección del deudor">
								<tec-input
									formControlName="direccionDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('direccionDeudor')?.invalid &&
											datosForm.get('direccionDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('direccionDeudor')?.invalid &&
										datosForm.get('direccionDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('direccionDeudor')?.invalid &&
										datosForm.get('direccionDeudor')?.touched
									"
								>
									La dirección es requerida.
								</div>
							</tec-form-group>
							<tec-form-group label="Código Postal">
								<tec-input
									formControlName="codigoPostalDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('codigoPostalDeudor')?.invalid &&
											datosForm.get('codigoPostalDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('codigoPostalDeudor')?.invalid &&
										datosForm.get('codigoPostalDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('codigoPostalDeudor')?.invalid &&
										datosForm.get('codigoPostalDeudor')?.touched
									"
								>
									El código postal es requerido.
								</div>
							</tec-form-group>
						</div>
						<div class="grid grid-i3">
							<tec-form-group label="Población">
								<tec-input
									formControlName="poblacionDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('poblacionDeudor')?.invalid &&
											datosForm.get('poblacionDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('poblacionDeudor')?.invalid &&
										datosForm.get('poblacionDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('poblacionDeudor')?.invalid &&
										datosForm.get('poblacionDeudor')?.touched
									"
								>
									La población es requerida.
								</div>
							</tec-form-group>
							<tec-form-group label="Provincia">
								<tec-input
									formControlName="provinciaDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('provinciaDeudor')?.invalid &&
											datosForm.get('provinciaDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('provinciaDeudor')?.invalid &&
										datosForm.get('provinciaDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('provinciaDeudor')?.invalid &&
										datosForm.get('provinciaDeudor')?.touched
									"
								>
									La provincia es requerida.
								</div>
							</tec-form-group>
							<tec-form-group label="País del deudor">
								<tec-input
									formControlName="paisDeudor"
									[ngClass]="{
										'input--error':
											datosForm.get('paisDeudor')?.invalid && datosForm.get('paisDeudor')?.touched
									}"
									[hasError]="
										datosForm.get('paisDeudor')?.invalid && datosForm.get('paisDeudor').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('paisDeudor')?.invalid && datosForm.get('paisDeudor')?.touched"
								>
									El país es requerido.
								</div>
							</tec-form-group>
						</div>
						<div class="grid grid-i3">
							<tec-form-group label="SWIFT BIC">
								<tec-input
									formControlName="SWIFT"
									[ngClass]="{
										'input--error':
											datosForm.get('SWIFT')?.invalid && datosForm.get('SWIFT')?.touched
									}"
									[hasError]="datosForm.get('SWIFT')?.invalid && datosForm.get('SWIFT').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('SWIFT')?.invalid && datosForm.get('SWIFT')?.touched"
								>
									El SWIFT es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="IBAN">
								<tec-input
									formControlName="IBAN"
									[ngClass]="{
										'input--error': datosForm.get('IBAN')?.invalid && datosForm.get('IBAN')?.touched
									}"
									[hasError]="datosForm.get('IBAN')?.invalid && datosForm.get('IBAN').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="datosForm.get('IBAN')?.invalid && datosForm.get('IBAN')?.touched"
								>
									El IBAN es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Localidad de la firma">
								<tec-input
									formControlName="localidadFirma"
									[ngClass]="{
										'input--error':
											datosForm.get('localidadFirma')?.invalid &&
											datosForm.get('localidadFirma')?.touched
									}"
									[hasError]="
										datosForm.get('localidadFirma')?.invalid &&
										datosForm.get('localidadFirma').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										datosForm.get('localidadFirma')?.invalid &&
										datosForm.get('localidadFirma')?.touched
									"
								>
									La localidad es requerida.
								</div>
							</tec-form-group>
						</div>
					</tec-tile>
				</ng-container>
				<ng-container *ngIf="isClickedSoc">
					<tec-tile
						type="default"
						[title]="'Logotipo'"
						subtitle="Tamaño deseado: 500 x 500 px"
						*ngIf="base64Logo === ''"
					>
						<tec-form-group>
							<tec-upload
								formControlName="logotipo"
								[size]="'sm'"
								[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
								(fileSelected)="convertToBase64($event)"
								[ngClass]="{
									'input--error':
										datosForm.get('logotipo')?.invalid && datosForm.get('logotipo')?.touched
								}"
							></tec-upload>
							<div
								class="form-group__error-message"
								*ngIf="datosForm.get('logotipo')?.invalid && datosForm.get('logotipo')?.touched"
							>
								El logotipo es requerido.
							</div>
						</tec-form-group>
					</tec-tile>
					<div class="layout--40-60" *ngIf="base64Logo !== ''">
						<div class="layout__column">
							<tec-tile type="default" [title]="'Logotipo'">
								<tec-tag-image [size]="'xl'" [img]="base64Logo"></tec-tag-image>
								<tec-link label="Eliminar" (click)="removeLogo()"></tec-link>
							</tec-tile>
						</div>
						<div class="layout__column">
							<tec-tile
								type="default"
								[title]="'Previsualización del logotipo'"
								subtitle="Así se verá tu logotipo, comprueba que la visualización es correcta."
							>
								<tec-media-action [img]="base64Logo">
									<!-- <tec-button-icon (click)="editLogo()" icon="icon-edit" /> -->
									<tec-button-icon (click)="removeLogo()" icon="icon-trash" />
								</tec-media-action>
							</tec-tile>
						</div>
					</div>
				</ng-container>
			</form>
			<tec-modal
				[isVisible]="modalVisible"
				[title]="rows.length > 0 ? 'Añadir otro mancomunado' : 'Añade tus datos de mancomunado'"
				buttonSecondaryLabel="Cancelar"
				buttonPrimaryLabel="Guardar"
				(closeEvent)="closeDialogModalEdit($event)"
			>
				<ng-container body>
					<form [formGroup]="modalForm">
						<div class="grid grid-i2">
							<tec-form-group label="Nombre y apellidos">
								<tec-input
									formControlName="nombre"
									[ngClass]="{
										'input--error':
											modalForm.get('nombre')?.invalid && modalForm.get('nombre')?.touched
									}"
									[hasError]="modalForm.get('nombre')?.invalid && modalForm.get('nombre').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="modalForm.get('nombre')?.invalid && modalForm.get('nombre')?.touched"
								>
									El nombre es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="DNI">
								<tec-input
									formControlName="dni"
									[ngClass]="{
										'input--error': modalForm.get('dni')?.invalid && modalForm.get('dni')?.touched
									}"
									[hasError]="modalForm.get('dni')?.invalid && modalForm.get('dni').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="modalForm.get('dni')?.invalid && modalForm.get('dni')?.touched"
								>
									El dni es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Email">
								<tec-input
									formControlName="email"
									[ngClass]="{
										'input--error':
											modalForm.get('email')?.invalid && modalForm.get('email')?.touched
									}"
									[hasError]="modalForm.get('email')?.invalid && modalForm.get('email').touched"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="modalForm.get('email')?.invalid && modalForm.get('email')?.touched"
								>
									El email es requerido.
								</div>
							</tec-form-group>
							<tec-form-group label="Teléfono">
								<tec-input
									formControlName="telefonoMancomunado"
									[ngClass]="{
										'input--error':
											modalForm.get('telefonoMancomunado')?.invalid &&
											modalForm.get('telefonoMancomunado')?.touched
									}"
									[hasError]="
										modalForm.get('telefonoMancomunado')?.invalid &&
										modalForm.get('telefonoMancomunado').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										modalForm.get('telefonoMancomunado')?.invalid &&
										modalForm.get('telefonoMancomunado')?.touched
									"
								>
									El teléfono es requerido.
								</div>
							</tec-form-group>
						</div>
					</form>
				</ng-container>
			</tec-modal>
		</ng-container>
		<!-- STEP 2 -->
		<ng-container *ngSwitchCase="2">
			<form [formGroup]="step2Form" class="gap-32">
				<tec-tile
					[title]="'Política de devoluciones'"
					subtitle="Las recompensas que generes a tus usuarios no serán efectivas hasta que no transcurra el plazo de devolución estipulado."
				>
					<div class="grid grid-i3">
						<tec-form-group label="¿Se permiten devoluciones?">
							<tec-toggle
								[options]="[
									{ id: 'si', label: 'Sí' },
									{ id: 'no', label: 'No' }
								]"
								[multiple]="false"
								(valueChange)="allowReturn($event)"
							></tec-toggle>
						</tec-form-group>
						<tec-form-group label="Plazo de devolución" *ngIf="isClickedSi">
							<tec-input formControlName="plazoDevolucion" placeholder="Máximo 90 días"></tec-input>
						</tec-form-group>
					</div>
				</tec-tile>
				<tec-tile
					[title]="'Aportación'"
					[subtitle]="
						'¿Tienes dudas o necesitas un tipo distinto de promoción? Escríbenos a soportecomercios@pensumo.es'
					"
				>
					<tec-form-group label="Tipo de aportación" class="grid grid-i2">
						<tec-select
							[placeholder]="'Selecciona el tipo de aportación'"
							[options]="aportaciones"
							[search]="true"
							(valueChange)="valueChange($event)"
							formControlName="tipoAportacion"
							[ngClass]="{
								'input--error':
									step2Form.get('tipoAportacion')?.invalid && step2Form.get('tipoAportacion')?.touched
							}"
							[hasError]="
								step2Form.get('tipoAportacion')?.invalid && step2Form.get('tipoAportacion').touched
							"
						></tec-select>
						<div
							class="form-group__error-message"
							*ngIf="step2Form.get('tipoAportacion')?.invalid && step2Form.get('tipoAportacion')?.touched"
						>
							El tipo de aportación es requerido.
						</div>
					</tec-form-group>
					<div class="grid grid-i3">
						<tec-form-group label="Porcentaje de aportación por compra (%)" *ngIf="aportSelected">
							<tec-input
								type="number"
								formControlName="aportacionCompra"
								placeholder="Mínimo 1%"
								[ngClass]="{
									'input--error':
										step2Form.get('aportacionCompra')?.invalid &&
										step2Form.get('aportacionCompra')?.touched
								}"
								[hasError]="
									step2Form.get('aportacionCompra')?.invalid &&
									step2Form.get('aportacionCompra').touched
								"
							></tec-input>
							<div
								class="form-group__error-message"
								*ngIf="
									step2Form.get('aportacionCompra')?.invalid &&
									step2Form.get('aportacionCompra')?.touched
								"
							>
								El porcentaje de aportación es requerido.
							</div>
						</tec-form-group>
						<tec-form-group label="Ticket mínimo de la compra (€)" *ngIf="showSecondPercent">
							<tec-input
								type="number"
								formControlName="aportacionCompraMinimoTicket"
								[ngClass]="{
									'input--error':
										step2Form.get('aportacionCompraMinimoTicket')?.invalid &&
										step2Form.get('aportacionCompraMinimoTicket')?.touched
								}"
								[hasError]="
									step2Form.get('aportacionCompraMinimoTicket')?.invalid &&
									step2Form.get('aportacionCompraMinimoTicket').touched
								"
							></tec-input>
							<div
								class="form-group__error-message"
								*ngIf="
									step2Form.get('aportacionCompraMinimoTicket')?.invalid &&
									step2Form.get('aportacionCompraMinimoTicket')?.touched
								"
							>
								La aportación mínima es requerida.
							</div>
						</tec-form-group>
						<tec-form-group label="Máximo de aportación (€)" *ngIf="showThirdPercent">
							<tec-input
								type="number"
								formControlName="aportacionCompraMaximoTicket"
								[ngClass]="{
									'input--error':
										step2Form.get('aportacionCompraMaximoTicket')?.invalid &&
										step2Form.get('aportacionCompraMaximoTicket')?.touched
								}"
								[hasError]="
									step2Form.get('aportacionCompraMaximoTicket')?.invalid &&
									step2Form.get('aportacionCompraMaximoTicket').touched
								"
							></tec-input>
							<div
								class="form-group__error-message"
								*ngIf="
									step2Form.get('aportacionCompraMaximoTicket')?.invalid &&
									step2Form.get('aportacionCompraMaximoTicket')?.touched
								"
							>
								La aportación máxima es requerida.
							</div>
						</tec-form-group>
					</div>
				</tec-tile>
			</form>
		</ng-container>
		<!-- STEP 3 -->
		<ng-container *ngSwitchCase="3">
			<ng-container *ngIf="formService.getForm().channel?.includes('ONLINE')">
				<form [formGroup]="step3Form">
					<tec-tile [title]="'Tienda online'" icon="icon-tv">
						<div class="grid grid-i3">
							<tec-form-group label="URL de tu página web de venta">
								<tec-input
									formControlName="urlWebVenta"
									[ngClass]="{
										'input--error':
											step3Form.get('urlWebVenta')?.invalid &&
											step3Form.get('urlWebVenta')?.touched
									}"
									[hasError]="
										step3Form.get('urlWebVenta')?.invalid && step3Form.get('urlWebVenta').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										step3Form.get('urlWebVenta')?.invalid && step3Form.get('urlWebVenta')?.touched
									"
								>
									La URL de la web es requerida.
								</div>
							</tec-form-group>
							<tec-form-group
								*ngIf="formService.getForm().channelBoth === 'ONLINE'"
								label="MerchantID o nº de comercio del TPV online"
								tooltip="Es el número de identificación de tu TPV online, puedes consultárselo al proveedor de pagos de tu web"
							>
								<tec-input formControlName="tpvOnline"></tec-input>
							</tec-form-group>
							<tec-form-group label="Email de contacto">
								<tec-input
									formControlName="emailContactoWebVenta"
									[ngClass]="{
										'input--error':
											step3Form.get('emailContactoWebVenta')?.invalid &&
											step3Form.get('emailContactoWebVenta')?.touched
									}"
									[hasError]="
										step3Form.get('emailContactoWebVenta')?.invalid &&
										step3Form.get('emailContactoWebVenta').touched
									"
								></tec-input>
								<div
									class="form-group__error-message"
									*ngIf="
										step3Form.get('emailContactoWebVenta')?.invalid &&
										step3Form.get('emailContactoWebVenta')?.touched
									"
								>
									El email de contacto de la web es requerido.
								</div>
							</tec-form-group>
						</div>
					</tec-tile>
				</form>
			</ng-container>

			<ng-container *ngIf="formService.getForm().channel?.includes('FISICO')">
				<ng-container
					*ngIf="
						formService.getForm().channel?.includes('ONLINE') &&
						rowsShops.length === 0 &&
						!formService.getForm().channelBoth?.includes('ONLINE')
					"
				>
					<tec-tile type="default" icon="icon-store" title="Establecimientos">
						<ng-container actions>
							<tec-button type="default" size="default" (click)="actions('add')">Añadir</tec-button>
							<tec-link (click)="actions('importar')"
								><tec-icon [name]="'icon-upload'" [size]="'xs'"></tec-icon
							></tec-link>
						</ng-container>
					</tec-tile>
				</ng-container>

				<!-- <div *ngIf="formService.getForm().shops.length === 0; else showTableShops"> -->
				<div *ngIf="!formService.getForm().channel?.includes('ONLINE') && rowsShops.length === 0">
					<tec-tile type="default" styling="default">
						<tec-empty-state
							[title]="'Todavía no hay ninguna tienda'"
							[description]="
								'Añade la información relativa a todos los establecimientos que quieras incorporar a la aplicación de Pensumo'
							"
							[size]="'default'"
							[type]="'empty'"
							[img]="'assets/images/shop_empty.png'"
						>
							<tec-button
								[label]="'Importar'"
								[size]="'sm'"
								[type]="'secondary'"
								[iconPosition]="'left'"
								icon="icon-upload"
								(clickEvent)="actions('importar')"
							></tec-button>
							<tec-button
								[label]="'Añadir'"
								[type]="'default'"
								[size]="'sm'"
								(clickEvent)="actions('add')"
							></tec-button>
						</tec-empty-state>
					</tec-tile>
				</div>
				<div *ngIf="rowsShops.length !== 0">
					<tec-tile [title]="'Establecimientos'" icon="icon-store">
						<tec-table-filter>
							<tec-toolbar
								[toolbar]="{
									search: { name: '', placeholder: 'Buscar' },
									button: { label: 'Añadir' },
									action: { id: 'upload', icon: 'icon-upload' },
									disabled: false
								}"
								(actionClick)="toolbarActions($event)"
							></tec-toolbar>
							<tec-table
								[headers]="headersShop"
								[rows]="rowsShops"
								*ngIf="rowsShops.length > 0"
								[config]="{ clickeable: false, checkbox: false, checkboxAll: false }"
								(cellEvent)="getMenu($event, 'shop')"
							></tec-table>
						</tec-table-filter>
					</tec-tile>
				</div>
			</ng-container>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="isClickedSoc || isClickedAut">
		<div class="pdd-info">
			<span>Información básica de protección de datos</span>
			<p>
				Pensumo, Pensión por Consumo, S.L. "tratará sus datos personales identificativos (nombre, apellidos,
				número de DNI) y profesionales (entidad empleadora/representada, correo electrónico profesional,
				teléfono profesional, tipología de relación con la entidad, tipología de representación, notario
				autorizante de la escritura de representaciónm, número de protocolo y fecha), como entidad legalmente
				responsable, en su condición de persona física representante de la sociedad, con la finalidad de
				ejecutar las acciones precontractuales que resulten necesarias para atender y tramitar el alta de dicha
				sociedad a la que representa, así como para formalizar y gestionar la posterior relación contractual con
				la misma. Tiene derecho a solicitar el acceso, la rectificación, la supresión y la portabilidad de sus
				datos personales, a oponerse o solicitar la limitación de ciertos tratamientos, así como ejercitar otros
				que le reconozca en cada momento la legislación vigente. Puede consultar información adicional sobre el
				tratamiento de sus datos personales, en especial sobre el ejercicio de sus derechos, en el
				<a
					href="https://media.ibercaja.net/pensumo/gdpr/comercios-personas-fisicas-contacto_v1.pdf"
					target="_blank"
					>Documento de Informacion Adicional de Protección de Datos.</a
				>
			</p>
		</div>
	</ng-container>

	<tec-footer-actions>
		<tec-button
			[label]="step === 1 ? 'Salir' : buttonLabel"
			[type]="'secondary'"
			(click)="buttonAction('BACK')"
		></tec-button>
		<tec-button [label]="'Continuar'" [type]="'default'" (click)="buttonAction(step + 1)"></tec-button>
	</tec-footer-actions>
</tec-create-layout>
<tec-modal
	[isVisible]="exitModal"
	buttonSecondaryLabel="Cancelar"
	buttonPrimaryLabel="Salir"
	(closeEvent)="closeDialogExitModalEdit($event)"
	[buttonPrimaryStyle]="true"
	type="confirmation"
>
	<ng-container body>
		<tec-tag-icon [size]="'default'" [icon]="'icon-alert'"></tec-tag-icon>
		<div class="body__text">
			<h4>¿Seguro que quieres salir de la solicitud de alta?</h4>
			<span> Podrás recuperar los datos siempre que uses este mismo <b>equipo y navegador</b> </span>
		</div>
	</ng-container>
</tec-modal>
<tec-modal
	[isVisible]="modalAddShop"
	title="Añadir establecimiento"
	buttonSecondaryLabel="Cancelar"
	buttonPrimaryLabel="Confirmar"
	(closeEvent)="closeModalShop($event)"
>
	<ng-container body>
		<form [formGroup]="modalShopForm">
			<div class="grid grid-i2">
				<tec-form-group label="Nombre del establecimiento">
					<tec-input formControlName="nombreEstablecimiento"></tec-input>
				</tec-form-group>
				<tec-form-group label="Email de contacto">
					<tec-input formControlName="emailEstablecimiento"></tec-input>
				</tec-form-group>
				<tec-form-group label="Dirección">
					<tec-input formControlName="direccionEstablecimiento"></tec-input>
				</tec-form-group>
				<tec-form-group label="Población">
					<tec-input formControlName="PoblacionEstablecimiento"></tec-input>
				</tec-form-group>
				<tec-form-group label="Provincia">
					<tec-select
						[placeholder]="'Selecciona el país'"
						[options]="provincias"
						[search]="true"
						(valueChange)="valueChange($event)"
						formControlName="provinciaEstablecimiento"
					></tec-select>
				</tec-form-group>
				<tec-form-group label="Código postal">
					<tec-input formControlName="cpEstablecimiento"></tec-input>
				</tec-form-group>
			</div>
		</form>
	</ng-container>
</tec-modal>
<tec-modal
	[isVisible]="modalAddShopMassive"
	title="Importar establecimientos"
	(closeEvent)="closeModalShopMassive($event)"
>
	<tec-alert
		[msg]="'Añade varios establecimientos a la vez. El formato de los datos debe ser igual al de la plantilla.'"
		[status]="'success'"
		[hiddenCloseButton]="true"
		[icon]="'icon-info'"
	></tec-alert>
	<form [formGroup]="importShops">
		<tec-form-group>
			<tec-upload
				[size]="'default'"
				[acceptedFileTypes]="['.xls', '.xlsx']"
				(fileSelected)="fileSelected($event)"
				[multiple]="false"
				formControlName="file"
			></tec-upload>
		</tec-form-group>
	</form>
	<div class="modal__content__footer">
		<a class="link" (click)="downloadTemplate()"
			><tec-icon [name]="'icon-download'" [size]="'xs'"></tec-icon>Descargar plantilla</a
		>
	</div>
</tec-modal>
<tec-snackbar [label]="'Tienda guardada correctamente'" [show]="showSnack"></tec-snackbar>

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISelect, ITableHeader, ITableRow } from '@tecalisdev/infinity';
import { FormService } from '../services/form.service';
import { PaisesService } from '../services/paises.service';
import { TableService } from '../services/table.service';
import { Router } from '@angular/router';
import { XlsxService } from '../services/xlsx.service';
import { MailService } from '../services/mail.service';

import {
	aportaciones,
	dniValidator,
	nifSociedadValidator,
	phoneValidator,
	refillDatosAutonomoForm,
	refillDatosForm,
	representations,
	sectores,
} from 'src/app/shared/utils/form-utils';

@Component({
	selector: 'app-registration-request',
	templateUrl: './registration-request.component.html',
	styleUrls: ['./registration-request.component.scss'],
})
export class RegistrationRequestComponent implements OnInit {
	registratonType!: string;
	steps = [
		{ label: 'Datos', selected: true, id: 1 },
		{ label: 'Promoción', selected: false, id: 2 },
		{ label: 'Tienda', selected: false, id: 3 },
	];
	step: number = 1;
	isClickedSoc: boolean = false;
	isClickedAut: boolean = false;
	isClickedSi: boolean = false;
	isClickedNo: boolean = true;
	showFirstPercent: boolean = false;
	showSecondPercent: boolean = false;
	showThirdPercent: boolean = false;
	paises: ISelect[] = [{ id: 1, label: 'España' }];
	provincias: ISelect[] = this.paisesService.getProvincias();
	sectores: ISelect[] = sectores();
	representations: ISelect[] = representations();
	aportaciones: ISelect[] = aportaciones();

	datosForm!: FormGroup;
	modalForm!: FormGroup;
	modalShopForm!: FormGroup;
	importShops!: FormGroup;
	step2Form!: FormGroup;
	step3Form!: FormGroup;

	headers: ITableHeader[] = [];
	headersShop: ITableHeader[] = [];
	rows: ITableRow[] = [];
	rowsShops: ITableRow[] = [];
	modalVisible: boolean = false;
	modalAddShop: boolean = false;
	modalAddShopMassive: boolean = false;
	rowIndex!: number | undefined;
	rowIndexShop!: number | undefined;
	showMancomunados: boolean = false;
	showSnack: boolean = false;

	buttonLabel: string = 'Volver';
	aportSelected: boolean = false;
	base64Logo: string = '';
	exitModal: boolean = false;

	constructor(
		private readonly location: Location,
		private fb: FormBuilder,
		public formService: FormService,
		private paisesService: PaisesService,
		private tableService: TableService,
		private router: Router,
		private xlsx: XlsxService,
		private mailService: MailService,
	) {
		this.datosForm = this.fb.group({
			pais: ['', Validators.required],
			nombreComercio: ['', Validators.required],
			web: ['', Validators.required],
			sector: ['', Validators.required],
			actividad: ['', Validators.required],
			hosteleriaAdherido: [''],
			volvemosAdherido: [''],
			nifSociedad: [''],
			razonSocSociedad: [''],
			emailSociedad: [''],
			representacionSociedad: [''],
			inscripcionSociedad: [''],
			nombreRepresentante: [''],
			dniRepresentante: [''],
			emailRepresentante: [''],
			telefonoProfesionalRepresentante: [''],
			escritura: [''],
			nombreAutonomo: [''],
			dniAutonomo: [''],
			razonSocAutonomo: [''],
			emailProfesional: [''],
			telefonoProfesional: [''],
			direccionFiscal: [''],
			nombreDeudor: ['', Validators.required],
			direccionDeudor: ['', Validators.required],
			codigoPostalDeudor: ['', Validators.required],
			poblacionDeudor: ['', Validators.required],
			provinciaDeudor: ['', Validators.required],
			paisDeudor: ['', Validators.required],
			SWIFT: ['', Validators.required],
			IBAN: ['', Validators.required],
			localidadFirma: ['', Validators.required],
			logotipo: [''],
		});

		this.modalForm = this.fb.group({
			nombre: ['', Validators.required],
			dni: ['', Validators.required],
			email: ['', Validators.required],
			telefonoMancomunado: ['', Validators.required],
		});

		this.step2Form = this.fb.group({
			plazoDevolucion: [''],
			tipoAportacion: ['', Validators.required],
			aportacionCompra: ['', Validators.required],
			aportacionCompraMinimoTicket: [''],
			aportacionCompraMaximoTicket: [''],
		});

		this.step3Form = this.fb.group({
			urlWebVenta: [''],
			tpvOnline: [''],
			emailContactoWebVenta: [''],
		});

		this.modalShopForm = this.fb.group({
			nombreEstablecimiento: [''],
			emailEstablecimiento: [''],
			direccionEstablecimiento: [''],
			PoblacionEstablecimiento: [''],
			provinciaEstablecimiento: [''],
			cpEstablecimiento: [''],
		});

		this.importShops = this.fb.group({
			file: [''],
		});

		this.headers = this.tableService.getHeaders('mancomunados');
		this.headersShop = this.tableService.getHeaders('shops');
	}

	ngOnInit(): void {
		const option = Number(this.location.path().split('/')[2]);
		this.registratonType = option === 0 ? 'CANAL ONLINE' : option === 1 ? 'CANAL FÍSICO' : 'CANAL ONLINE Y FÍSICO';

		this.paisesService.getPaises().subscribe({
			next: (paises) => {
				this.paises = paises
					.map((pais: any, index: number) => ({ id: index, label: pais.name.common }))
					.sort((a: any, b: any) => {
						return a.label.localeCompare(b.label, 'es');
					});
			},
			error: () => {},
		});

		if (this.formService.getForm().businessType) {
			const datos = this.formService.getForm();
			if (this.formService.getForm().businessType === 'Sociedad') {
				this.step = datos.tab!;
				this.steps = this.steps.map((item) =>
					item.id === datos.tab! ? { ...item, selected: true } : { ...item, selected: false },
				);

				this.datosForm.patchValue(refillDatosForm(this.formService));

				if (datos.mancomunados && datos.mancomunados.length > 0) {
					datos.mancomunados.forEach((mancomunado) => {
						this.addMancomunadoRow(
							mancomunado.nombre,
							mancomunado.dni,
							mancomunado.email,
							mancomunado.telefonoMancomunado,
						);
					});
					this.showMancomunados = true;
				}

				this.isClickedSoc = true;
			}
			if (this.formService.getForm().businessType === 'Autonomo') {
				this.step = datos.tab!;
				this.steps = this.steps.map((item) =>
					item.id === datos.tab! ? { ...item, selected: true } : { ...item, selected: false },
				);

				this.datosForm.patchValue(refillDatosAutonomoForm(this.formService));

				this.isClickedAut = true;
			}

			//forms comunes a ambos
			this.step2Form.patchValue({
				plazoDevolucion: datos.plazoDevolucion !== '' ? datos.plazoDevolucion : '',
				tipoAportacion: datos.tipoAportacion?.id,
				aportacionCompra: datos.aportacionCompra,
				aportacionCompraMinimoTicket: datos.aportacionCompraMinimoTicket,
				aportacionCompraMaximoTicket: datos.aportacionCompraMaximoTicket,
			});

			this.isClickedSi = datos.plazoDevolucion !== '';
			this.isClickedNo = datos.plazoDevolucion === '';
			this.aportSelected = true;

			this.step3Form.patchValue({
				urlWebVenta: datos.channel?.includes('ONLINE') ? datos.urlWebVenta : '',
				tpvOnline:
					datos.channel?.includes('ONLINE') && datos.channelBoth && datos.channelBoth === 'AMBOS'
						? datos.tpvOnline
						: '',
				emailContactoWebVenta: datos.channel?.includes('ONLINE') ? datos.emailContactoWebVenta : '',
			});

			if (datos.channel?.includes('FISICO')) {
				if (datos.shops && datos.shops.length > 0) {
					this.rowsShops = datos.shops;
				}
			}
		}
	}

	clickEvent(event: any) {
		if (this.datosForm.valid) {
			this.steps = this.steps.map((item) =>
				item.id === event.id ? { ...item, selected: true } : { ...item, selected: false },
			);
			this.step = this.steps.find((item) => item.selected)!.id;
		} else this.datosForm.markAllAsTouched();
	}

	allowReturn(event: any) {
		if (event.id === 'si') {
			this.isClickedSi = true;
			this.isClickedNo = false;
		}
		if (event.id === 'no') {
			this.isClickedSi = false;
			this.isClickedNo = true;
		}
	}

	buttonAction(option: string | number) {
		if (option === 'BACK') {
			if (this.step === 1) {
				this.exitModal = true;
			}
			if (this.step === 2) {
				this.step = 1;
				this.clickEvent({ id: 1 });
			}
			if (this.step === 3) {
				this.step = 2;
				this.clickEvent({ id: 2 });
			}
			return;
		}

		//validate step 1
		if (this.step === 1) {
			if (this.datosForm.valid) {
				option === 'BACK' ? this.location.back() : Number(option) ? (this.step = Number(option)) : null;
				if (Number(option) && Number(option) <= 3) this.clickEvent({ id: Number(option) });
				this.formService.setForm(this.datosForm.getRawValue());

				if (this.datosForm.getRawValue().escritura && this.datosForm.getRawValue().logotipo) {
					this.mailService.convertToBase64(this.datosForm.getRawValue().escritura[0], 'escritura');
					this.mailService.convertToBase64(this.datosForm.getRawValue().logotipo[0], 'logo');
				}

				if (this.isClickedSoc && this.rows.length > 0)
					this.formService.setForm({
						mancomunados: this.rows.map((row: any) => {
							return {
								nombre: row.cells[0].label,
								dni: row.cells[1].label,
								email: row.cells[2].label,
								telefonoMancomunado: row.cells[3].label,
							};
						}),
					});
			} else {
				this.datosForm.markAllAsTouched();
			}
		}

		//validate step 2
		if (this.step === 2) {
			if (this.step2Form.valid) {
				//setear validadores a step 3
				if (
					this.formService.getForm()?.channel?.includes('ONLINE') ||
					this.formService.getForm()?.channelBoth === 'AMBOS'
				) {
					this.step3Form.get('urlWebVenta')?.setValidators(Validators.required);
					this.step3Form.get('emailContactoWebVenta')?.setValidators(Validators.required);
				} else {
					this.step3Form.get('urlWebVenta')?.clearValidators();
					this.step3Form.get('emailContactoWebVenta')?.clearValidators();
				}

				option === 'BACK' ? this.location.back() : Number(option) ? (this.step = Number(option)) : null;
				if (Number(option) && Number(option) <= 3) this.clickEvent({ id: Number(option) });
				if (Number(option) === 3) {
					if (this.isClickedNo) this.step2Form.get('plazoDevolucion')?.patchValue('');
					this.formService.setForm(this.step2Form.getRawValue());
				}
			} else {
				this.step2Form.markAllAsTouched();
			}
		}

		//validate step 3
		if (this.step === 3) {
			if (this.step3Form.valid) {
				option === 'BACK' ? this.location.back() : Number(option) ? (this.step = Number(option)) : null;
				if (Number(option) && Number(option) <= 3) this.clickEvent({ id: Number(option) });
				if (Number(option) === 4) {
					this.formService.setForm(this.step3Form.getRawValue());
					if (this.rowsShops.length !== 0) this.formService.setForm({ shops: this.rowsShops });
					this.router.navigate(['/resume']);
				}
			} else {
				this.step3Form.markAllAsTouched();
			}
		}
	}

	addMancomunado() {
		this.modalVisible = true;
	}

	closeDialogExitModalEdit(event: any) {
		switch (event) {
			//Salir
			case 'buttonPrimary':
				this.exitModal = false;
				this.formService.resetForm(), this.router.navigate(['/']);
				break;
			//Cancelar
			case 'buttonSecondary':
				this.exitModal = false;
				break;
			//Cerrar
			default:
				this.exitModal = false;
				break;
		}
	}

	addMancomunadoRow(nombre: string, dni: string, email: string, telefonoMancomunado: string) {
		this.rows.push({
			clickValue: '',
			cells: [
				{
					label: nombre,
				},
				{
					label: dni,
				},
				{
					label: email,
				},
				{
					label: telefonoMancomunado,
				},
				{
					align: 'right',
					label: '',
					buttonIcon: {
						id: 'button2dropdown',
						icon: 'icon-more',
						menu: [
							{
								label: 'Editar',
								icon: 'icon-edit',
								id: 'option0',
							},
							{
								label: 'Eliminar',
								style: 'red',
								icon: 'icon-trash',
								id: 'option1',
								divider: true,
							},
						],
					},
				},
			],
		});
	}

	closeDialogModalEdit(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				if (!!this.modalForm.valid) {
					const { nombre, dni, email, telefonoMancomunado } = this.modalForm.getRawValue();
					if (!this.rowIndex && isNaN(this.rowIndex!))
						this.addMancomunadoRow(nombre, dni, email, telefonoMancomunado);
					else {
						this.rows[this.rowIndex!].cells[0].label = nombre;
						this.rows[this.rowIndex!].cells[1].label = dni;
						this.rows[this.rowIndex!].cells[2].label = email;
						this.rows[this.rowIndex!].cells[3].label = telefonoMancomunado;

						this.rowIndex = undefined;
					}

					this.modalForm.reset();
					this.modalVisible = false;
				} else {
					this.modalForm.markAllAsTouched();
				}
				break;
			//Cancelar
			case 'buttonSecondary':
				this.modalVisible = false;
				break;
			//Cerrar
			default:
				this.modalVisible = false;
				break;
		}
	}

	getMenu(event: any, type: string) {
		if (type === 'mancomunado') {
			if (event?.cellId?.label === 'Editar') {
				this.modalVisible = true;
				this.rowIndex = event.rowIndex;
				const rowSelected = this.rows[this.rowIndex!].cells;
				const params = ['nombre', 'dni', 'email', 'telefonoMancomunado'];

				rowSelected.forEach((cell, index) => {
					this.modalForm.get(params[index])?.patchValue(cell.label);
				});
			}
			if (event?.cellId.label === 'Eliminar') {
				this.rows.splice(event.rowIndex, 1);
			}
		}

		if (type === 'shop') {
			if (event?.cellId?.label === 'Editar') {
				this.modalAddShop = true;
				this.rowIndexShop = event.rowIndex;
				const rowSelected = this.rowsShops[this.rowIndexShop!].cells;
				const params = [
					'nombreEstablecimiento',
					'emailEstablecimiento',
					'direccionEstablecimiento',
					'PoblacionEstablecimiento',
					'provinciaEstablecimiento',
					'cpEstablecimiento',
				];

				rowSelected.forEach((cell, index) => {
					this.modalShopForm.get(params[index])?.patchValue(cell.label);
				});
			}
			if (event?.cellId.label === 'Eliminar') {
				this.rowsShops.splice(event.rowIndex, 1);
			}
		}
	}

	changeClass(event: any) {
		if (event.label) this.formService.setForm({ businessType: event.label });
		if (event.label === 'Sociedad') {
			this.isClickedSoc = true;
			this.isClickedAut = false;
			//Clear validators autonomo
			this.clearValidators([
				'nombreAutonomo',
				'dniAutonomo',
				'razonSocAutonomo',
				'emailProfesional',
				'telefonoProfesional',
				'direccionFiscal',
			]);

			this.addValidators([
				'nifSociedad',
				'razonSocSociedad',
				'emailSociedad',
				'representacionSociedad',
				'inscripcionSociedad',
				'nombreRepresentante',
				'dniRepresentante',
				'emailRepresentante',
				'telefonoProfesionalRepresentante',
				'escritura',
				'logotipo',
			]);
		}
		if (event.label === 'Autónomo') {
			this.isClickedSoc = false;
			this.isClickedAut = true;
			//Clear validators sociedad
			this.clearValidators([
				'nifSociedad',
				'razonSocSociedad',
				'emailSociedad',
				'representacionSociedad',
				'inscripcionSociedad',
				'nombreRepresentante',
				'dniRepresentante',
				'emailRepresentante',
				'telefonoProfesionalRepresentante',
				'escritura',
				'logotipo',
			]);

			this.addValidators([
				'nombreAutonomo',
				'dniAutonomo',
				'razonSocAutonomo',
				'emailProfesional',
				'telefonoProfesional',
				'direccionFiscal',
			]);
		}
	}

	clearValidators(keys: any) {
		keys.forEach((key: string) => {
			const control = this.datosForm.get(key);
			if (control) {
				control.clearValidators();
				control.updateValueAndValidity();
			}
		});
		this.datosForm.updateValueAndValidity();
	}

	addValidators(keys: any) {
		keys.forEach((key: string) => {
			let control = this.datosForm.get(key);
			if (control) {
				if (key === 'dniAutonomo' || key === 'dniRepresentante') control.setValidators(dniValidator());
				if (key === 'emailProfesional' || key === 'emailSociedad' || key === 'emailRepresentante')
					control.setValidators(Validators.email);
				if (key === 'telefonoProfesional' || key === 'telefonoProfesionalRepresentante')
					control.setValidators(phoneValidator());
				if (key === 'nifSociedad')
					control.setValidators(nifSociedadValidator()), control.setValidators(Validators.required);
				else control.setValidators(Validators.required);
				control.updateValueAndValidity();
			}
			this.datosForm.updateValueAndValidity();
		});
	}

	valueChange(event: any) {
		this.aportSelected = true;
		if (event.id === 'porcentaje_compra')
			(this.showFirstPercent = true), (this.showSecondPercent = false), (this.showThirdPercent = false);
		if (event.id === 'porcentaje_compra_minimo')
			(this.showSecondPercent = true), (this.showFirstPercent = false), (this.showThirdPercent = false);
		if (event.id === 'porcentaje_compra_maximo')
			(this.showThirdPercent = true), (this.showFirstPercent = false), (this.showSecondPercent = false);
		if (event.id === 'Mancomunado') this.showMancomunados = true;
		else this.showMancomunados = false;
	}

	valueChangeCheck(val: boolean) {}

	actions(event: any) {
		if (event === 'add') {
			this.modalAddShop = true;
		}
		if (event === 'importar') {
			this.modalAddShopMassive = true;
		}
	}

	closeModalShop(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				const {
					nombreEstablecimiento,
					emailEstablecimiento,
					direccionEstablecimiento,
					PoblacionEstablecimiento,
					provinciaEstablecimiento,
					cpEstablecimiento,
				} = this.modalShopForm.getRawValue();
				if (!this.rowIndexShop && isNaN(this.rowIndexShop!))
					this.rowsShops.push({
						clickValue: '',
						cells: [
							{
								label: nombreEstablecimiento,
							},
							{
								label: emailEstablecimiento,
							},
							{
								label: direccionEstablecimiento,
							},
							{
								label: PoblacionEstablecimiento,
							},
							{
								label: provinciaEstablecimiento.label,
							},
							{
								label: cpEstablecimiento,
							},
							{
								align: 'right',
								label: '',
								buttonIcon: {
									id: 'button2dropdown',
									icon: 'icon-more',
									menu: [
										{
											label: 'Editar',
											icon: 'icon-edit',
											id: 'option0',
										},
										{
											label: 'Eliminar',
											style: 'red',
											icon: 'icon-trash',
											id: 'option1',
											divider: true,
										},
									],
								},
							},
						],
					});
				else {
					this.rowsShops[this.rowIndexShop!].cells[0].label = nombreEstablecimiento;
					this.rowsShops[this.rowIndexShop!].cells[1].label = emailEstablecimiento;
					this.rowsShops[this.rowIndexShop!].cells[2].label = direccionEstablecimiento;
					this.rowsShops[this.rowIndexShop!].cells[3].label = PoblacionEstablecimiento;
					this.rowsShops[this.rowIndexShop!].cells[4].label = provinciaEstablecimiento.label;
					this.rowsShops[this.rowIndexShop!].cells[5].label = cpEstablecimiento;

					this.rowIndexShop = undefined;
				}

				this.modalShopForm.reset();
				this.modalAddShop = false;
				this.showSnack = true;
				setTimeout(() => {
					this.showSnack = false;
				}, 2000);
				break;
			//Cancelar
			case 'buttonSecondary':
				this.modalShopForm.reset();
				this.modalAddShop = false;
				break;
			//Cerrar
			default:
				this.modalShopForm.reset();
				this.modalAddShop = false;
				break;
		}
	}

	closeModalShopMassive(event: any) {
		switch (event) {
			//Guardar
			case 'buttonPrimary':
				this.modalAddShopMassive = false;
				break;
			//Cancelar
			case 'buttonSecondary':
				this.modalAddShopMassive = false;
				break;
			//Cerrar
			default:
				this.modalAddShopMassive = false;
				break;
		}
	}

	fileSelected(file: any) {
		this.xlsx.readFile(file[0]);

		this.xlsx.getFileData().subscribe({
			next: (data) => {
				this.rowsShops = data;
				this.modalAddShopMassive = false;
				this.showSnack = true;
				setTimeout(() => {
					this.showSnack = false;
				}, 2000);
			},
			error: () => {
				this.rowsShops = [];
				this.modalAddShopMassive = false;
			},
		});
	}

	toolbarActions(event: any) {
		if (event?.label === 'Añadir') this.modalAddShop = true;
		if (event?.id === 'upload') this.modalAddShopMassive = true;
	}

	downloadTemplate() {
		this.xlsx.generateTemplate();
	}

	convertToBase64(file: any): void {
		const reader = new FileReader();

		reader.onload = () => {
			this.base64Logo = reader.result as string;
		};

		reader.readAsDataURL(file[0]);
	}

	removeLogo() {
		this.base64Logo = '';
	}

	editLogo() {}
}

<ng-container *ngIf="step === 1">
	<tec-auth-layout
		img="assets/images/pensumo-logo.svg"
		imgTitle=""
		title="Únete a Pensumo"
		subTitle="Un plan de fidelización único para los comercios"
		watermark="false"
	>
		<ng-container body>
			<img src="assets/images/media-img.svg" alt="Pensumo" class="auth__body__image-pre-login">
			<tec-button size="lg" label="Unirme" (click)="step = 2"></tec-button>
			<!-- <tec-button size="lg" label="Unirme" (click)="isCookieAccepted && changeStep()" [disabled]="!isCookieAccepted"></tec-button> -->
		</ng-container>
	</tec-auth-layout>
</ng-container>
<ng-container *ngIf="step === 2">
	<tec-auth-layout
		img="assets/images/pensumo-logo.svg"
		imgTitle=""
		[title]="bothSelected ? bothTitle : title"
		[subTitle]="bothSelected ? bothSubTitle : subTitle"
		watermark="false"
	>
		<ng-container body *ngIf="!bothSelected; else showViewBoth">
			<tec-item
				[tagImage]="{ icon: 'icon-tv', status: 'level' }"
				title="Online"
				[selected]="isSelectedOption[0]"
				(click)="selectOption(0, 'CANAL ONLINE')"
				size="lg"
			>
				<tec-list-detail>
					<span>Solo vendo a través de mi web</span>
				</tec-list-detail>
			</tec-item>
			<tec-item
				[tagImage]="{ icon: 'icon-store', status: 'level' }"
				title="Físico"
				[selected]="isSelectedOption[1]"
				(click)="selectOption(1, 'CANAL FISICO')"
				size="lg"
			>
				<tec-list-detail>
					<span>Solo vendo en mis locales físicos</span>
				</tec-list-detail>
			</tec-item>
			<tec-item
				[tagImage]="{ icon: 'icon-both', status: 'level' }"
				title="Ambos"
				[selected]="isSelectedOption[2]"
				(click)="selectOption(2, 'CANAL ONLINE Y FISICO')"
				size="lg"
			>
				<tec-list-detail>
					<span>Vendo tanto en mis locales físicos como a través de mi web</span>
				</tec-list-detail>
			</tec-item>
			<div class="auth__body__actions"></div>
			<tec-button size="lg" label="Continuar" (clickEvent)="navigate()" [disabled]="disabled"></tec-button>
		</ng-container>
		<ng-template #showViewBoth>
			<ng-container body>
				<tec-item
					[tagImage]="{ icon: 'icon-tv', status: 'level' }"
					title="Online"
					[selected]="isSelectedOptionBoth[0]"
					(click)="selectOptionBoth(0, 'ONLINE')"
					size="lg"
				>
					<tec-list-detail>
						<span>Solo se recompensarán las ventas a través de mi web</span>
					</tec-list-detail>
				</tec-item>
				<tec-item
					[tagImage]="{ icon: 'icon-both', status: 'level' }"
					title="Ambos"
					[selected]="isSelectedOptionBoth[1]"
					(click)="selectOptionBoth(1, 'AMBOS')"
					size="lg"
				>
					<tec-list-detail>
						<span>Se recompensarán tanto las ventas online como las ventas en tiendas físicas</span>
					</tec-list-detail>
				</tec-item>
				<div class="auth__body__actions"></div>
				<tec-button size="lg" label="Continuar" (clickEvent)="navigate('both')" [disabled]="disabled"></tec-button>
			</ng-container>
		</ng-template>
	</tec-auth-layout>
</ng-container>

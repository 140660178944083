<tec-confirmation-layout
	title="Resumen"
	[tag]="{
		status: 'success',
		label: formService.getForm().channelBoth === 'ONLINE' ? 'CANAL ONLINE' : formService.getForm().channel!,
		type: 'default'
	}"
>
	<ng-container>
		<tec-tile type="default" title="Datos">
			<ng-container actions>
				<tec-link
					label="Editar"
					(click)="
						edit(
							formService.getForm().channel === 'CANAL ONLINE'
								? '0'
								: formService.getForm().channel === 'CANAL FISICO'
								? '1'
								: '2',
							1
						)
					"
				></tec-link>
			</ng-container>
			<div class="tile__body__column">
				<!-- AUTONOMO -->
				<div *ngIf="formService.getForm().businessType === 'Autónomo'" class="grid grid-i2">
					<div class="info-group">
						<b>Nombre del comercio</b>
						<span>{{ formService.getForm().nombreComercio || '' }}</span>
					</div>
					<div class="info-group">
						<b>Tipo de negocio</b>
						<span>{{ formService.getForm().businessType || '' }}</span>
					</div>
					<div class="info-group">
						<b>Nombre del autónomo</b>
						<span>{{ formService.getForm().nombreAutonomo || '' }}</span>
					</div>
					<div class="info-group">
						<b>DNI</b>
						<span>{{ formService.getForm().dniAutonomo }}</span>
					</div>
				</div>
				<!-- SOCIEDAD -->
				<div *ngIf="formService.getForm().businessType === 'Sociedad'" class="grid grid-i3">
					<div class="info-group">
						<b>Nombre del comercio</b>
						<span>{{ formService.getForm().nombreComercio || '' }}</span>
					</div>
					<div class="info-group">
						<b>Tipo de negocio</b>
						<span>{{ formService.getForm().businessType || '' }}</span>
					</div>
					<div class="info-group">
						<b *ngIf="formService.getForm().nifSociedad !== ''">NIF</b>
						<b *ngIf="formService.getForm().dniAutonomo !== ''">DNI</b>
						<span>{{ formService.getForm().nifSociedad || formService.getForm().dniAutonomo }}</span>
					</div>
					<div class="info-group" *ngIf="formService.getForm().businessType === 'Sociedad'">
						<b>Representante</b>
						<span>{{ formService.getForm().nifSociedad || formService.getForm().nombreRepresentante || '' }}</span>
					</div>
					<div class="info-group" *ngIf="formService.getForm().businessType === 'Sociedad'">
						<b>Forma de representación</b>
						<span>{{ formService.getForm().representacionSociedad?.id || '' }}</span>
					</div>
					<div class="info-group" *ngIf="formService.getForm().representacionSociedad?.id === 'Mancomunado'">
						<b>Nº adms. mancomunados</b>
						<tec-badge [label]="formService.getForm().mancomunados?.length || 0" [type]="'square'"></tec-badge>
					</div>
				</div>
			</div>
		</tec-tile>
		<div class="grid grid-e2">
			<tec-tile type="default" title="Promoción">
				<ng-container actions>
					<tec-link
						label="Editar"
						(click)="
							edit(
								formService.getForm().channel === 'CANAL ONLINE'
									? '0'
									: formService.getForm().channel === 'CANAL FISICO'
									? '1'
									: '2',
								2
							)
						"
					></tec-link>
				</ng-container>
				<div class="info-group">
					<b>Tipo de aportación</b>
					<span>{{ formService.getForm().tipoAportacion?.label || '' }}</span>
				</div>
				<div class="info-group">
					<b>Porcentaje de aportación por compra (%)</b>
					<span>{{ formService.getForm().aportacionCompra || '' }}</span>
				</div>
				<div class="info-group" *ngIf="formService.getForm().tipoAportacion?.id === 'porcentaje_compra_minimo'">
					<b>Ticket mínimo de la compra (€)</b>
					<span>{{ formService.getForm().aportacionCompraMinimoTicket || 0 }}</span>
				</div>
				<div class="info-group" *ngIf="formService.getForm().tipoAportacion?.id === 'porcentaje_compra_maximo'">
					<b>Máximo de aportación (€)</b>
					<span>{{ formService.getForm().aportacionCompraMaximoTicket || 0 }}</span>						
				</div>
			</tec-tile>
			<tec-tile
				type="default"
				title="Tienda online"
				icon="icon-tv"
				*ngIf="
					formService.getForm()?.channel! === 'CANAL ONLINE' ||
					(formService.getForm()?.channelBoth && formService.getForm()?.channelBoth! === 'ONLINE')
				"
			>
				<ng-container actions>
					<tec-link
						label="Editar"
						(click)="
							edit(
								formService.getForm().channel === 'CANAL ONLINE'
									? '0'
									: formService.getForm().channel === 'CANAL FISICO'
									? '1'
									: '2',
								3
							)
						"
					></tec-link>
				</ng-container>
				<div class="tile__body__column">
					<div class="grid grid-i1">
						<div class="info-group">
							<b>URL de tu página web de venta</b>
							<span>{{ formService.getForm().urlWebVenta || '' }}</span>
						</div>
					</div>
				</div>
			</tec-tile>
			<tec-tile
				*ngIf="formService.getForm()?.channel! === 'CANAL FISICO' && !formService.getForm()?.channelBoth"
				type="default"
				title="Establecimientos"
				icon="icon-store"
			>
				<ng-container actions>
					<tec-link
						label="Editar"
						(click)="
							edit(
								formService.getForm().channel === 'CANAL ONLINE'
									? '0'
									: formService.getForm().channel === 'CANAL FISICO'
									? '1'
									: '2',
								3
							)
						"
					></tec-link>
				</ng-container>
				<div class="tile__body__column">
					<ul class="check-list">
						<li class="check-list__item" *ngFor="let item of formService.getForm().shops.slice(0, visibleItemsCount)">
							{{ item.cells[0].label }}
						</li>
					</ul>
				</div>
				<tec-link (click)="showAll()">{{ visibleItemsCount === 5 ? 'Ver más' : 'Ver menos' }}</tec-link>
			</tec-tile>
			<tec-tile
				*ngIf="
					formService.getForm()?.channel! === 'CANAL ONLINE Y FISICO' &&
					formService.getForm()?.channelBoth &&
					formService.getForm()?.channelBoth === 'AMBOS'
				"
				type="default"
				title="Tiendas"
			>
				<ng-container actions>
					<tec-link
						label="Editar"
						(click)="
							edit(
								formService.getForm().channel === 'CANAL ONLINE'
									? '0'
									: formService.getForm().channel === 'CANAL FISICO'
									? '1'
									: '2',
								3
							)
						"
					></tec-link>
				</ng-container>
				<div class="tile__body__column">
					<div class="grid grid-i1">
						<span>TIENDAS ONLINE</span>
						<div class="info-group">
							<b>URL de tu página web de venta</b>
							<span>{{ formService.getForm().urlWebVenta || '' }}</span>
						</div>
					</div>
					<br>
					<div class="grid grid-i1 mt-3">
						<span>ESTABLECIMIENTOS</span>
						<div class="info-group">
							<ul class="check-list">
								<li class="check-list__item" *ngFor="let item of formService.getForm().shops.slice(0, visibleItemsCount)">
									{{ item.cells[0].label }}
								</li>
							</ul>
						</div>
						<tec-link (click)="showAll()">{{ visibleItemsCount === 5 ? 'Ver más' : 'Ver menos' }}</tec-link>
					</div>
				</div>
			</tec-tile>
		</div>
	</ng-container>
	<tec-footer-actions>
		<tec-button
			[label]="step === 1 ? 'Volver atrás' : buttonLabel"
			[type]="'secondary'"
			(click)="buttonAction('BACK')"
		></tec-button>
		<tec-button [label]="'Continuar'" [type]="'default'" (click)="buttonAction('CONTINUE')"></tec-button>
	</tec-footer-actions>
</tec-confirmation-layout>

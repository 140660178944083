import { NgModule } from '@angular/core';

import {
	AlertComponent,
	AuthLayoutComponent,
	AvatarComponent,
	BadgeComponent,
	BaseComponent,
	ButtonCollapseComponent,
	ButtonComponent,
	ButtonIconComponent,
	CardAccessComponent,
	CheckboxComponent,
	ConfirmationComponent,
	CookiesComponent,
	CreateLayoutComponent,
	DetailLayoutComponent,
	DividerComponent,
	EmptyStateComponent,
	FooterActionsComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	ItemComponent,
	LinkComponent,
	ListDetailComponent,
	MediaActionComponent,
	ModalComponent,
	SelectComponent,
	SnackbarComponent,
	StepperProgressComponent,
	SummaryComponent,
	SwitchComponent,
	SwitchFormComponent,
	TableComponent,
	TableFilterComponent,
	TagComponent,
	TagIconComponent,
	TextareaComponent,
	TileComponent,
	TitleGroupComponent,
	ToolbarComponent,
	TooltipComponent,
	TopnavComponent,
	UploadComponent,
	ToggleComponent,
	TagImageComponent,
	ToggleAcceptComponent,
} from '@tecalisdev/infinity';

@NgModule({
	declarations: [],
	imports: [
		AuthLayoutComponent,
		ButtonComponent,
		ButtonCollapseComponent,
		InputComponent,
		LinkComponent,
		DividerComponent,
		IconComponent,
		AlertComponent,
		CheckboxComponent,
		BaseComponent,
		CardAccessComponent,
		TileComponent,
		ItemComponent,
		ListDetailComponent,
		TagComponent,
		StepperProgressComponent,
		CreateLayoutComponent,
		FormGroupComponent,
		SelectComponent,
		TextareaComponent,
		UploadComponent,
		TopnavComponent,
		FooterActionsComponent,
		TableComponent,
		ModalComponent,
		DetailLayoutComponent,
		TitleGroupComponent,
		ConfirmationComponent,
		BadgeComponent,
		SwitchComponent,
		SwitchFormComponent,
		TooltipComponent,
		EmptyStateComponent,
		TableFilterComponent,
		ToolbarComponent,
		SnackbarComponent,
		TagIconComponent,
		MediaActionComponent,
		ButtonIconComponent,
		AvatarComponent,
		CookiesComponent,
		ToggleComponent,
		TagImageComponent,
		ToggleAcceptComponent,
	],
	exports: [
		AuthLayoutComponent,
		ButtonComponent,
		ButtonCollapseComponent,
		InputComponent,
		LinkComponent,
		DividerComponent,
		IconComponent,
		AlertComponent,
		CheckboxComponent,
		BaseComponent,
		CardAccessComponent,
		TileComponent,
		ItemComponent,
		ListDetailComponent,
		TagComponent,
		StepperProgressComponent,
		CreateLayoutComponent,
		FormGroupComponent,
		SelectComponent,
		TextareaComponent,
		UploadComponent,
		TopnavComponent,
		FooterActionsComponent,
		TableComponent,
		ModalComponent,
		DetailLayoutComponent,
		TitleGroupComponent,
		ConfirmationComponent,
		BadgeComponent,
		SwitchComponent,
		SwitchFormComponent,
		TooltipComponent,
		EmptyStateComponent,
		TableFilterComponent,
		ToolbarComponent,
		SnackbarComponent,
		TagIconComponent,
		MediaActionComponent,
		ButtonIconComponent,
		AvatarComponent,
		CookiesComponent,
		ToggleComponent,
		TagImageComponent,
		ToggleAcceptComponent,
	],
	providers: [],
	bootstrap: [],
})
export class DesignModule {}
